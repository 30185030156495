<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 mx-auto">
      <KTPortlet title="Chỉnh sửa Gift Card" headSize="md">
        <template v-slot:body>
          <b-form @submit.prevent="onSubmit">
            <b-form-group
              id="input-group-1"
              label="Tên / Mã Gift Card"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                type="text"
                v-model="form.code"
                :disabled="form.status === 2 || form.status === 3"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Tên khách hàng"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                type="text"
                v-model="form.customerName"
                :disabled="form.status === 3"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              label="Giá trị"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                type="number"
                required
                v-model="form.value"
                :disabled="form.status === 2 || form.status === 3"
              ></b-form-input>
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              :disabled="form.status === 3"
              >Hoàn thành</b-button
            >
          </b-form>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<style lang="scss">
@import "./GiftCard.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import axios from "@/common/api.js";
import { updateSuccess } from "@/helper/index";

export default {
  components: {
    KTPortlet,
    vSelect
  },
  data() {
    return {
      form: {
        code: "",
        price: 0,
        value: 0,
        outletId: "",
        customerName: ""
      }
    };
  },
  created() {
    const item = this.$router.history.current.params?.item;
    if (item) {
      this.form = { ...this.form, ...item };
      this.form.customerName = item.customer.name;
    }
  },

  methods: {
    onSubmit() {
      const params = {
        price: this.form.value,
        value: this.form.value,
        code: this.form.code,
        id: this.form._id
      };
      this.form.customerName && (params.customerName = this.form.customerName);
      axios
        .post("/secure/r2/gift-card/update", params)
        .then(res => {
          updateSuccess("gift-card");
        })
        .catch(e => {
          Swal.fire({
            icon: "error",
            title: "Cập nhật không thành công!",
            text: e.errors
          });
        });
    }
  }
};
</script>
